import React, { Component } from "react";
import QrReader from 'react-qr-scanner'


import BitTotemBackend from "./contracts/BitTotemBackend.json";
import getWeb3 from "./getWeb3";

import "./App.css";
import Navbar from "./components/Navbar";

import 'bootstrap/dist/css/bootstrap.min.css';


class CustomQRReader extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: 'No result',
    }

    this.handleScan = this.handleScan.bind(this)
  }

  handleScan(data){
    this.setState({
      result: data,
    })
    if(data != null) {
      console.log(data.text);
      document.getElementById('CVC').value = data.text;
      this.props.validateFunction();
    }
  }

  handleError(err){
    console.error(err)
  }

  render(){
    const previewStyle = {
      height: 320,
      width: 320,
    }

    return(
      <div>
        <QrReader
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
          />
        <div id="#qrdata"></div>
      </div>
    );
  }
}

class App extends Component {
  state = {
    web3: null,
    accounts: null,
    contract: null,
    deviceLicenseFee: null,
    currentTokenId: null
  };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();
      const deployedNetwork = BitTotemBackend.networks[networkId];
      const instance = new web3.eth.Contract(
        BitTotemBackend.abi,
        deployedNetwork && deployedNetwork.address,
      );

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contract: instance }, this.runExample);

      instance.events.DeviceCreated({}, (error, data) => {
        if (error) {
          console.log("Error: " + error);
        } else {
          console.log("Log data: " + data);
          this.runExample();
        }
      });

      instance.events.DeviceOwnerChangedUsername({}, (error, data) => {
      if (error) {
        console.log("Error: " + error);
      } else {
        console.log("Log data: " + data);
        this.runExample();
        }
      });

    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`,
      );
      console.error(error);
    }
  };

  runExample = async () => {
    const { accounts, contract } = this.state;

    const currentTokenId = await contract.methods.currentTokenId().call()
    this.setState({ currentTokenId })

    let deviceLicenseFee = await contract.methods.deviceLicenseFee().call()
    console.log(this.state.web3.utils.fromWei(String(deviceLicenseFee),"ether"))
    this.setState({ deviceLicenseFee })
  };

  mint = () => {
    //const reqValue = this.state.web3.utils.toWei("0.1", "ether")
    this.state.contract.methods.anyoneCreateDevice().send({from: this.state.accounts[0], value: this.state.deviceLicenseFee})
    .once('receipt', async (receipt) => {
      const currentTokenId = await this.state.contract.methods.currentTokenId().call()
      this.setState({ currentTokenId })
      alert(`Congradulations! You now own BitTotem Device # ${ currentTokenId }!`)
    })
  }

  validate = async () => {
    // CDS: 1 04-17-2021 16:16:18 -756 94 -735 100000110000000000000000 SIG: 0x51cfc228f28786034396bd384ebcaaa3f41daf7c34b33ce767572ef26bee1ad65dd5996c5d0688f5148a776fc8f9bb27a5b10f30094da3fd69e30df5e740b3761b
    document.getElementById('CVC').style.backgroundColor = "white";
    document.getElementById('CVC').style.color = "black";

    var val = document.getElementById('CVC').value;

    const myregex = /(CDS: (\d{1,}) .+) SIG: (.*)/;
    const match = val.match(myregex)
    console.log(`\nCDS Text: ${match[1]}\nTokenID: ${match[2]}\nSignature: ${match[3]}`);

    const cds = match[1];
    const nft = match[2];
    const sig = match[3];

    const result = await this.state.contract.methods.cvcIsValidFromMsgStr(cds, sig, nft).call({from: this.state.accounts[0]})
    const device = await this.state.contract.methods.devices(nft-1).call()
    console.log(`device: ${device}`)

    if(result) {
      document.getElementById('CVC').style.backgroundColor = "darkgreen";
      document.getElementById('CVC').style.color = "white";
      //alert("Valid: This message is properly signed by the purported owner.");
      document.getElementById('username').innerHTML = "Verified User: " + device;
    } else {
      document.getElementById('CVC').style.backgroundColor = "darkred";
      document.getElementById('CVC').style.color = "white";
      //alert("INVALID: The message may have been tampered with.");
      document.getElementById('username').innerHTML = "INVALID";

    }
  }

  handleChange(event) {
    document.getElementById('CVC').style.backgroundColor = "white";
    document.getElementById('CVC').style.color = "black";
    //this.validate();
  }

  render() {
    if (!this.state.web3) {
      return <div>Loading Web3, accounts, and contract...</div>;
    }
    return (
      <div className="App">
        <Navbar title="BitTotem" url="https://bittotem.com" account={this.state.accounts[0]} />

          <p></p>

          <CustomQRReader validateFunction={this.validate}/>

          <div id="username"></div>
          <form>
          <textarea id="CVC" rows="8" cols="33" onChange={(event) => {console.log(event); this.validate();}}></textarea>
          </form>

          <form onSubmit={(event) => {
              event.preventDefault()
              this.validate()
          }}>

          <input
            id="validate"
            type="submit"
            className="btn btn-block btn-primary"
            value="Validate CVC"
          />
          </form>

          <p></p>
          <h1>Get your own BitTotem ID</h1>
          <form onSubmit={(event) => {
            event.preventDefault()
            this.mint()
          }}>

          <input
            id="submit"
            type="submit"
            className="btn btn-block btn-warning"
            value="Get a BitTotem (1 Eth)!"
          />
          </form>

      </div>
    );
  }
}

export default App;
