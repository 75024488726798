import React, { Component } from 'react';
import Jdenticon from 'react-jdenticon';

class Navbar extends Component {

  render() {
    return (
      /*
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
        <a
          className="navbar-brand col-sm-3 col-md-2 mr-0"
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
        BillionDollarDapp
        </a>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            <small className="text-white"><span id="account">{this.state.accounts[0]}</span></small>
          </li>
        </ul>
      </nav> */

      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
        <a
          className="navbar-brand col-sm-3 col-md-2 mr-0"
          href={this.props.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.props.title}
        </a>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            <small className="text-white"><span id="account">{this.props.account}</span></small>
            { this.props.account ? <span align="right"><Jdenticon size="30" value={this.props.account} /></span> : <span></span> }
          </li>

        </ul>
      </nav>
    );
  }
}

export default Navbar;
